import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MarketplaceModal from '../../components/Modal/Modal';
import { useLocation } from 'react-router-dom';

function ShareModal(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  return <div>This is share modal</div>;
}

export default ShareModal;
