import { useEffect, useState } from 'react';

const getScroll = () => {
  const xScroll = (window && window.scrollX) || 0;
  const yScroll = (window && window.scrollY) || 0;

  const hasYScrolled = yScroll > 0;

  const windowInnerHeight = window?.innerHeight || 0;
  const bodyOffset = document?.body?.offsetHeight - 2 || 0;

  const hasScrolledToBottom =
    hasYScrolled && Math.round(windowInnerHeight + window.pageYOffset) >= Math.round(bodyOffset);

  return {
    xScroll,
    yScroll,
    hasYScrolled,
    hasScrolledToBottom,
  };
};

/**
 *  onlyOnScrollEnd {If true, fires the event only when the user stops scrolling.}
 */

function useWindowScroll(options = {}) {
  const [windowScroll, setWindowScroll] = useState(getScroll());

  const { onlyOnScrollEnd = false } = options;

  useEffect(() => {
    let scrollId;

    const handleScroll = () => {
      if (onlyOnScrollEnd) {
        clearTimeout(scrollId);
        scrollId = setTimeout(() => setWindowScroll(getScroll()), 200);
      } else {
        setWindowScroll(getScroll());
      }
    };

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
      if (onlyOnScrollEnd && scrollId) {
        clearTimeout(scrollId);
      }
    };
  }, []);

  return windowScroll;
}

export default useWindowScroll;
