import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory, Route } from 'react-router-dom';
import { useWallet } from 'use-wallet';
import { useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';

// Components
import MarketplaceModal from '../Modal/Modal';
import CustomDropDownButton from '../CustomDropDownButton/CustomDropDownButton';

import Spinner from '../Spinner/Spinner';
import { SnackbarNotification } from '../Snackbar/Snackbar';

// assets
import UserDummy from '../../assets/images/prod/default-user-avatar-dark.png';
import { ReactComponent as DiscordLogo } from '../../assets/images/prod/discord-link.svg';
import { ReactComponent as TelegramLogo } from '../../assets/images/prod/telegram-link.svg';
import { ReactComponent as FacebookLogo } from '../../assets/images/prod/fb-link.svg';
import { ReactComponent as TwitterLogo } from '../../assets/images/prod/twitter-link.svg';
import { ReactComponent as PadLockLogo } from '../../assets/images/prod/padlock.svg';
import { ReactComponent as DownArrow } from '../../assets/images/down-arrrow.svg';

//styles
import './SideBar.scss';
import { logoutUserRequest, setWalletConnectModal, setSelectedSeries } from '../../store/actions';
import { getShortenedAddress } from '../../utils/helpers';
import { useEagerConnect, useInactiveListener, useUserAccount } from '../../hooks';

const SideBar = ({ onThemeToggle }) => {
  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState();
  const [spinner, setSpinner] = useState(false);

  const user = useSelector((state) => state.userReducer.user);
  const showWalletConnectModal = useSelector((state) => state.dummyReducer.showWalletConnectModal);

  const { connector, deactivate, active } = useWeb3React();
  const { status } = useWallet();
  const { account } = useUserAccount();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  const WalletLoginLazy = React.lazy(() => import('../../views/WalletLogin/WalletLogin'));
  const [reqOptions, setReqOptions] = useState({ label: 'Squid Game', value: 'squidGame' });

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    if (account) {
      dispatch(setWalletConnectModal(false));
      if (active || status === 'connected') {
        localStorage.setItem('shouldEagerConnect', true);
        history.push('/avatar');
      }
    }
  }, [account]);

  const onLogoutClick = (event) => {
    event.preventDefault();

    dispatch(logoutUserRequest({ account }));
    localStorage.setItem('shouldEagerConnect', false);
    deactivate();
    history.push('/');
  };

  const NavbarLink = ({ title, redirectTo, isActive = false, className = '', disabled }) => {
    return (
      // original to={condition} commented out for now
      // !!account ? redirectTo : '/'

      <>
        <Link to={redirectTo} className={`${className} ${disabled && 'disabled'} ${isActive ? 'isActive' : ''}`}>
          {title.toUpperCase()}
          {disabled && <PadLockLogo className='navbar-navigation-lock-icon' />}
        </Link>
      </>
    );
  };

  const SeriesNavbarLink = ({ title, redirectTo, isActive = false, className = '', disabled }) => {
    return (
      // original to={condition} commented out for now
      // !!account ? redirectTo : '/'

      <>
        <Link to={redirectTo} className={`${className} ${disabled && 'disabled'} ${isActive ? 'isActive' : ''}`}>
          {title.toUpperCase()}
          <DownArrow className={`dropdown-toogle-item-expandable-arrow open}`} />
        </Link>
      </>
    );
  };

  const showSelectedSeriesHome = (series) => {
    dispatch(setSelectedSeries(series));
  };

  return (
    <>
      {spinner && <Spinner />}
      <SnackbarNotification />

      {
        <MarketplaceModal show={showWalletConnectModal} onClose={() => dispatch(setWalletConnectModal(false))}>
          <WalletLoginLazy />
        </MarketplaceModal>
      }

      <div className='navbar-main-container'>
        <div className='navbar-header-container'>
          <div className='navbar-header-text'>{window.innerWidth > 400 ? 'TORUM AVATAR NFT' : 'AVATAR NFT'}</div>
          {window.innerWidth > 660 ? (
            <div className='navbar-header-right-container'>
              <div className='navbar-header-social-media-link-container'>
                <a
                  href='https://discord.gg/torum'
                  className='navbar-header-social-media-link'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <DiscordLogo className='navbar-header-social-media-icon' />
                </a>
                <a
                  href='https://t.me/torum_official_group_en'
                  className='navbar-header-social-media-link'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <TelegramLogo className='navbar-header-social-media-icon' />
                </a>
                <a
                  href='https://www.facebook.com/torum.official'
                  className='navbar-header-social-media-link'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <FacebookLogo className='navbar-header-social-media-icon' />
                </a>
                <a
                  href='https://twitter.com/torum_official'
                  className='navbar-header-social-media-link'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <TwitterLogo className='navbar-header-social-media-icon' />
                </a>
              </div>
              <div className='navbar-visit-torum-button'>
                <a
                  href='https://intro.torum.com'
                  className='navbar-visit-torum-link'
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  VISIT TORUM
                </a>
              </div>
            </div>
          ) : (
            <div onClick={() => dispatch(setWalletConnectModal(true))} className='navbar-connect-wallet-container'>
              {window.innerWidth > 500 ? 'CONNECT WALLET' : 'CONNECT'}
            </div>
          )}
        </div>
        <div className='navbar-navigation-container'>
          <div className='navbar-navigation-left-wrapper'>
            <div className='navbar-navigation-item-container'>
              {/* <NavbarLink
                redirectTo='/'
                title='Home'
                isActive={location.pathname === '/'}
                className='navbar-navigation-item home'
              /> */}
              {/* <Link
                to={location?.pathname !== '/' && '/'}
              > */}
              {location?.pathname === '/' ? (
                <CustomDropDownButton
                  title='Series'
                  onDropdownSelect={showSelectedSeriesHome}
                  defaultValue={reqOptions.value || ''}
                  options={[
                    { label: 'Genesis (Ended)', value: 'genesis' },
                    { label: 'Squid Game', value: 'squidGame' },
                  ]}
                  show={location?.pathname === '/'}
                />
              ) : (
                <SeriesNavbarLink
                  redirectTo='/'
                  title='Series'
                  isActive={location.pathname === '/'}
                  className='series-navbar-navigation-item home'
                />
              )}
              {/* </Link> */}
            </div>
            <div className='navbar-navigation-item-container disabled'>
              <NavbarLink
                redirectTo='/avatar'
                // disabled
                // redirectTo=''
                title='Inventory'
                isActive={location.pathname === '/avatar'}
                className='navbar-navigation-item avatar disabled'
              />
            </div>
            <div className='navbar-navigation-item-container disabled'>
              <a
                href='https://nft.torum.com/avatar'
                rel='noopener noreferrer'
                className='navbar-navigation-item marketplace'
                target='_blank'
              >
                MARKETPLACE
              </a>
              {/* <span className='navbar-navigation-item marketplace disabled'>
                MARKETPLACE <PadLockLogo className='navbar-navigation-lock-icon' />
              </span> */}
            </div>
          </div>
          <div className='navbar-navigation-right-wrapper'>
            {/* NOTE: THIS DISCORD BUTTON SHOULD BE REMOVED WHEN WE ALLOW USER TO CONNECT WALLET  */}
            <a
              href='https://discord.gg/torum'
              className='navbar-header-social-media-link'
              target='_blank'
              rel='noreferrer noopener'
            >
              <div className='navbar-join-discord-button'>JOIN DISCORD</div>
            </a>

            {/* TODO: Remove this condition so all users can browse ?  */}
            {status === 'connected' || active ? (
              <>
                <div className='navbar-profile-container'>
                  <img className={`navbar-profile-image`} src={user?.profilePic || UserDummy} alt='Profile' />
                  <div className='navbar-username-address-container'>
                    <div className='navbar-username-container'>{user?.userName || 'Guest'}</div>
                    {account && (
                      <div className='navbar-address-container'>
                        <span className=''>{getShortenedAddress(account)}</span>
                      </div>
                    )}
                  </div>
                </div>
                {account && (
                  <div className='navbar-logout-container' onClick={onLogoutClick}>
                    LOG OUT
                  </div>
                )}
              </>
            ) : (
              window.innerWidth > 660 && (
                <div onClick={() => dispatch(setWalletConnectModal(true))} className='navbar-connect-wallet-container'>
                  {window.innerWidth > 500 ? 'CONNECT WALLET' : 'CONNECT'}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
