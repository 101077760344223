import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
// import resetIcon from '../../assets/images/reset.svg';
// import selectedIcon from '../../assets/images/svg/selection.svg';
import { useLocation } from 'react-router-dom';
import './CustomDropDownButton.scss';
import { ReactComponent as DownArrow } from '../../assets/images/down-arrrow.svg';

const CustomDropDownButton = ({ title, icon, options, onDropdownSelect, defaultValue, show }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [menuShowing, setMenuShowing] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const popperConfig = {
    strategy: 'fixed',
  };

  return (
    <Dropdown style={{ transform: 'none' }} onToggle={(show) => setMenuShowing(show)}>
      <Dropdown.Toggle
        className={`filter-button home ${location?.pathname === '/' ? 'isActive' : ''}`}
        id={`dropdown-basic${title}`}
        onToggle={(val) => console.log(val)}
      >
        {title.toUpperCase()}
        <DownArrow className={`dropdown-toogle-item-expandable-arrow ${menuShowing && 'open'}`} />
      </Dropdown.Toggle>

      {location?.pathname === '/' && (
        <Dropdown.Menu
          popperConfig={popperConfig}
          style={{ padding: '16px 24px 0' }}
          onToggle={(val) => console.log(val)}
        >
          {options.map(({ label, value }) => {
            return (
              <Dropdown.Item
                active={value === selectedValue}
                key={value}
                onClick={() => {
                  if (selectedValue !== value) {
                    setSelectedValue(value);
                    onDropdownSelect(value);
                  }
                }}
              >
                <span className='filter-label'>{label}</span>
                {value === selectedValue}
                {!value}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default CustomDropDownButton;
