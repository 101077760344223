import React, { useEffect, useState } from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';
import { useWallet } from 'use-wallet';
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom';
import MetaMaskOnboarding from '@metamask/onboarding';

// components
import Spinner from './components/Spinner/Spinner';
import AppLayout from './views/AppLayout/AppLayout';
import MarketplaceModal from './components/Modal/Modal';
import GuestUserModal from './views/Login/GuestUserModal';
import ShareModal from './views/ShareModal/ShareModal';
import SynchronizeWalletModal from './views/Login/SynchronizeWalletModal';
import LoginAndSynchronizationModal from './views/Login/LoginAndSynchronizationModal';
import ChangePasswordModal from './views/Login/ChangePasswordModal';

// hooks
import { useEagerConnect, useInactiveListener, useUserAccount } from './hooks';

// actions
import { getTorumUserProfileRequest, setLoginModal, setSyncToTorumModal, getUserVerify } from './store/actions';

// assets
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faHeart } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// constants

import './App.scss';
import { getTorumToken } from './utils/helpers';

//svg
import { ReactComponent as WrongNetworkSvg } from './assets/images/prod/binance-smartchain-mainnet-example.svg';

library.add(fas, far, faHeart);

const App = () => {
  const [activatingConnector, setActivatingConnector] = useState();
  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(MetaMaskOnboarding.isMetaMaskInstalled());

  const { active, connector, error, chainId } = useWeb3React();
  const { status } = useWallet();
  const { account } = useUserAccount();

  const showLoginModal = useSelector((state) => state.userReducer.showLoginModal);
  const showSyncToTorumModal = useSelector((state) => state.userReducer.showSyncToTorumModal);
  // const isAuthOngoing = useSelector((state) => state.userReducer.isAuthOngoing);
  // const isGuest = useSelector((state) => state.userReducer.isGuest);
  const hasTorumToken = Boolean(getTorumToken());

  const dispatch = useDispatch();

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled() && isMetaMaskInstalled === false) {
      setIsMetaMaskInstalled(true);
    }
  }, [isMetaMaskInstalled]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  useEffect(() => {
    if (account && (active || status === 'connected')) {
      const torumToken = getTorumToken();

      if (torumToken) {
        dispatch(getTorumUserProfileRequest());
      }
    }
  }, [dispatch, account, active, status]);

  // useEffect(() => {
  //   const torumToken = getTorumToken();

  //   if (!torumToken) {
  //     dispatch(setLoginModal(true));
  //     dispatch(setSyncToTorumModal(false));
  //   }
  // }, []);

  useEffect(() => {
    const torumToken = getTorumToken();
    if (torumToken && localStorage.getItem('avatar_access_level') === '3') {
      dispatch(setSyncToTorumModal('changePassword'));
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const code = urlParams.get('oobCode');

    const params = {
      token: code,
    };
    if (mode === 'loginwithemail' && code) {
      localStorage.clear();
      dispatch(getUserVerify({ data: params }));
    }
  }, []);

  // useEffect(() => {
  //   const torumToken = getTorumToken();

  //   if (torumToken || isGuest) {
  //     dispatch(setLoginModal(false));
  //     dispatch(setSyncToTorumModal(false));
  //     return;
  //   }

  //   if (!isAuthOngoing && !torumToken && !isGuest) {
  //     dispatch(setLoginModal(true));
  //     dispatch(setSyncToTorumModal(false));
  //   }
  // }, [isAuthOngoing, account, isGuest]);

  return (
    <React.Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <AppLayout>
          <Switch>
            <Route path='/' component={React.lazy(() => import('./views/MainContainer/MainContainer'))} />;
          </Switch>

          {/* TODO: Decide a position of this alert */}
          {/*<MarketplaceModal show={!isMetaMaskInstalled}>*/}
          {/*  {!isMetaMaskInstalled && (*/}
          {/*    <h5 style={{ color: 'red' }}>Please install MetaMask to get real-time data on marketplace</h5>*/}
          {/*  )}*/}
          {/*</MarketplaceModal>*/}

          {error instanceof UnsupportedChainIdError && (
            <MarketplaceModal show={!!error}>
              <div className='mb-2'>
                <div className='row mt-4 text-center ml-auto mr-auto wrong-network'>
                  <div className='wrong-network-modal-container'>
                    <div className='wrong-network-modal-title'>Wrong Network</div>
                    <div className='wrong-network-modal-description'>Please connect to Binance Smart Chain Mainnet</div>
                    <div className='wrong-network-modal-image-container'>
                      <WrongNetworkSvg />
                    </div>
                  </div>
                </div>
              </div>
            </MarketplaceModal>
          )}

          {showLoginModal && !hasTorumToken && (
            <SynchronizeWalletModal
              show={showLoginModal}
              setShowModal={(val) => dispatch(setLoginModal(val))}
              onProceedHandle={(val) => {
                dispatch(setLoginModal(false));
                dispatch(setSyncToTorumModal(val));
              }}
            />
          )}

          {showSyncToTorumModal === 'torumLander' && !hasTorumToken && (
            <LoginAndSynchronizationModal
              show={showSyncToTorumModal}
              setShowModal={(val) => dispatch(setSyncToTorumModal(val))}
              goBack={() => {
                dispatch(setLoginModal(false));
                dispatch(setSyncToTorumModal(null));
              }}
            />
          )}

          {showSyncToTorumModal === 'changePassword' && (
            <ChangePasswordModal
              show={showSyncToTorumModal}
              setShowModal={(val) => dispatch(setSyncToTorumModal(val))}
              // goBack={() => {
              //   dispatch(setLoginModal(false));
              //   dispatch(setSyncToTorumModal(null));
              // }}
            />
          )}

          {showSyncToTorumModal === 'newcomer' && !hasTorumToken && (
            <GuestUserModal
              show={showSyncToTorumModal}
              setShowModal={(val) => dispatch(setSyncToTorumModal(val))}
              goBack={() => {
                dispatch(setLoginModal(true));
                dispatch(setSyncToTorumModal(null));
              }}
            />
          )}
        </AppLayout>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;
