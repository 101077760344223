import * as actionLabels from '../../actionLabels';

const initialState = {
  open: false,
  message: '',
  duration: 0,
  severity: '',
};

export default (state = {}, action) => {
  switch (action.type) {
    case actionLabels.SNACKBAR_SHOW:
      return {
        ...state,
        open: true,
        message: action.payload.message,
        duration: action.payload.duration || 3000,
        severity: action.payload.severity || 'info',
      };
    case actionLabels.SNACKBAR_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
