import * as actionLabels from '../../actionLabels';
import axiosApiCaller from '../../../http/axios/apiCaller';
import apiURLs from '../../../utils/apiURLs';
import { showSnackbar } from '../snackbar/snackbar';
import axios from 'axios';
import { getTorumToken } from '../../../utils/helpers';

export const loginWithTorumRequest = (payload) => {
  return {
    type: actionLabels.GET_SYNCHRONIZE_USER_PROFILE_REQUEST,
    payload,
  };
};
export const registerWithTorumRequest = (payload) => {
  return {
    type: actionLabels.GET_SYNCHRONIZE_REGISTERED_USER_PROFILE_REQUEST,
    payload,
  };
};
export const getUserVerify = (payload) => {
  return {
    type: actionLabels.GET_USER_VERIFY,
    payload,
  };
};

export const loginWithTorumSuccess = (payload) => {
  return {
    type: actionLabels.GET_SYNCHRONIZE_USER_PROFILE_SUCCESS,
    payload,
  };
};

export const loginWithTorumFail = (payload) => {
  return {
    type: actionLabels.GET_SYNCHRONIZE_USER_PROFILE_FAIL,
    payload,
  };
};

export const updateUserProfileImageRequest = (payload) => {
  return {
    type: actionLabels.UPDATE_USER_PROFILE_IMAGE_REQUEST,
    payload,
  };
};

export const updateUserProfileImageSuccess = (payload) => {
  return {
    type: actionLabels.UPDATE_USER_PROFILE_IMAGE_SUCCESS,
    payload,
  };
};

export const logoutUserRequest = (payload) => ({
  type: actionLabels.LOGOUT_USER_REQUEST,
  payload,
});

export const logoutUserSuccess = () => ({
  type: actionLabels.LOGOUT_USER_SUCCESS,
});

export const getTorumUserProfileRequest = (payload) => {
  return {
    type: actionLabels.GET_TORUM_USER_PROFILE_REQUEST,
  };
};

export const getTorumUserProfileSuccess = (payload) => {
  return {
    type: actionLabels.GET_TORUM_USER_PROFILE_SUCCESS,
    payload,
  };
};

export const getTorumUserProfileFail = (payload) => {
  return {
    type: actionLabels.GET_TORUM_USER_PROFILE_FAIL,
    payload,
  };
};

export const updateTorumUserProfileRequest = (payload) => {
  return {
    type: actionLabels.UPDATE_TORUM_USER_PROFILE_REQUEST,
    payload,
  };
};

// Not used since on Torum UserProfile Update call, we're getting same user object of Torum
// We're saving it by calling getTorumUserProfileSuccess action again to avoid duplication
export const updateTorumUserProfileSuccess = (payload) => {
  return {
    type: actionLabels.UPDATE_TORUM_USER_PROFILE_SUCCESS,
    payload,
  };
};

export const updateTorumUserProfileFail = (payload) => {
  return {
    type: actionLabels.UPDATE_TORUM_USER_PROFILE_FAIL,
    payload,
  };
};

export const updateImageRequest = (payload) => {
  return {
    type: actionLabels.UPDATE_IMAGE_REQUEST,
    payload,
  };
};

export const updateImageSuccess = (payload) => {
  return {
    type: actionLabels.UPDATE_IMAGE_SUCCESS,
    payload,
  };
};

export const updateCoverImageSuccess = (payload) => {
  return {
    type: actionLabels.UPDATE_COVER_IMAGE_SUCCESS,
    payload,
  };
};

export const updateImageFail = (payload) => {
  return {
    type: actionLabels.UPDATE_IMAGE_FAIL,
    payload,
  };
};

export const originalProfilePic = (payload) => {
  return {
    type: actionLabels.ORIGINAL_PROFILE_PIC,
    payload,
  };
};

export const guestUserRequest = (payload) => {
  return {
    type: actionLabels.GUEST_USER_REQUEST,
    payload,
  };
};

export const guestUserSuccess = (payload) => {
  return {
    type: actionLabels.GUEST_USER_SUCCESS,
    payload,
  };
};

export const guestUserFail = (payload) => {
  return {
    type: actionLabels.GUEST_USER_FAIL,
    payload,
  };
};

export const setIsAuthOngoing = (payload) => {
  return {
    type: actionLabels.AUTH_ONGOING,
    payload,
  };
};

export const setIsGuest = (payload) => {
  return {
    type: actionLabels.SET_IS_GUEST,
    payload,
  };
};

export const setLoginModal = (payload) => {
  return {
    type: actionLabels.SET_LOGIN_MODAL,
    payload,
  };
};

export const setSyncToTorumModal = (payload) => {
  return {
    type: actionLabels.SET_SYNC_TO_TORUM_MODAL,
    payload,
  };
};

export const setShareOnTorumModal = (payload) => {
  return {
    type: actionLabels.SET_SHOW_SHARE_ON_TORUM_MODAL,
    payload,
  };
};

export const setShareIntent = (payload) => {
  return {
    type: actionLabels.SET_SHARE_INTENT,
    payload,
  };
};

export const setPostMediaUploadOngoing = (payload) => {
  return {
    type: actionLabels.SET_POST_MEDIA_UPLOAD_ONGOING,
    payload,
  };
};

export const setCreatePostOngoing = (payload) => {
  return {
    type: actionLabels.SET_CREATE_POST_ONGOING,
    payload,
  };
};

// uploads post media and then reposts it
export const uploadPostMedia = (payload) => async (dispatch, getState) => {
  const { mediaUploadParams, postParams } = payload;

  try {
    let bodyFormData = new FormData();
    bodyFormData.append('media', mediaUploadParams.file);
    bodyFormData.append('type', 'post');
    bodyFormData.append('ext', mediaUploadParams.ext);

    dispatch(setPostMediaUploadOngoing(true));
    const response = await axiosApiCaller(apiURLs.uploadPostMedia, 'POST', bodyFormData);

    if (response.status === 200) {
      const media = [
        {
          url: response.data.data.url,
          type: mediaUploadParams.type,
        },
      ];

      dispatch(setPostMediaUploadOngoing(false));

      postParams.media = media;

      dispatch(doRepost({ postParams }));
    } else {
      dispatch(setPostMediaUploadOngoing(false));
      dispatch(
        showSnackbar({
          message: 'Something went wrong. Please try again!',
          severity: 'error',
        })
      );
    }
  } catch (e) {
    console.error(e);
    dispatch(setPostMediaUploadOngoing(false));
    dispatch(
      showSnackbar({
        message: 'Something went wrong. Please try again!',
        severity: 'error',
      })
    );
  }
};

// returns the AWS URL only
export const savePostMedia = (payload) => async (dispatch, getState) => {
  try {
    let formData = new FormData();

    Object.keys(payload).map((key) => {
      formData.append(key, payload[key]);
    });

    dispatch(setPostMediaUploadOngoing(true));
    const response = await axiosApiCaller(apiURLs.uploadPostMedia, 'POST', formData);

    if (response.status === 200) {
      dispatch(setPostMediaUploadOngoing(false));
      return Promise.resolve(response.data.data.url);
    } else {
      dispatch(setPostMediaUploadOngoing(false));
      dispatch(
        showSnackbar({
          message: 'Something went wrong. Please try again!',
          severity: 'error',
        })
      );
    }
  } catch (e) {
    console.error(e);
    dispatch(setPostMediaUploadOngoing(false));
    dispatch(
      showSnackbar({
        message: 'Something went wrong. Please try again!',
        severity: 'error',
      })
    );
  }
};

// saves revealed NFT watermarked image for sharing
export const uploadUserMedia = (payload) => async (dispatch) => {
  try {
    let formData = new FormData();

    Object.keys(payload).map((key) => {
      formData.append(key, payload[key]);
    });

    const response = await axiosApiCaller(apiURLs.uploadUserMedia, 'POST', formData);

    if (response.status === 200) {
      return Promise.resolve(response.data.data.url);
    }
  } catch (e) {
    console.error(e);
    dispatch(
      showSnackbar({
        message: 'Something went wrong. Please try again later!',
        severity: 'error',
      })
    );
    return Promise.reject(e);
  }
};

export const doRepost = (payload) => async (dispatch, getState) => {
  dispatch(setCreatePostOngoing(true));

  try {
    const response = await axiosApiCaller(apiURLs.repost, 'POST', payload.postParams);

    if (response.data?.success) {
      dispatch(setCreatePostOngoing(false));
      dispatch(setShareOnTorumModal(false));
      dispatch(
        showSnackbar({
          message: 'Your Avatar has been shared on Torum successfully!.',
          severity: 'success',
        })
      );
    }
  } catch (e) {
    console.error(e);
    dispatch(setCreatePostOngoing(false));
    dispatch(
      showSnackbar({
        message: 'Something went wrong while creating post. Please try again!',
        severity: 'error',
      })
    );
  }
};

export const setMentionSuggestionOngoing = (payload) => {
  return {
    type: actionLabels.SET_MENTION_SUGGESTION_ONGOING,
    payload,
  };
};

export const resetEditorReducer = () => {
  return {
    type: actionLabels.RESET_EDITOR_REDUCER,
  };
};

export const setMentions = (val) => {
  return {
    type: actionLabels.SET_MENTIONS_LIST,
    payload: val,
  };
};

export const resetMentions = () => {
  return {
    type: actionLabels.RESET_MENTIONS_LIST,
  };
};

export const getMentionSuggestion = (params) => async (dispatch, getState) => {
  try {
    dispatch(setMentionSuggestionOngoing(true));

    const response = await axiosApiCaller(apiURLs.getUserSearch, 'GET', '', params);

    if (response?.data?.success) {
      if (response.data.data) {
        dispatch({
          type: actionLabels.SET_MENTION_SUGGESTION_SUCCESS,
          payload: response.data.data,
        });

        dispatch(setMentionSuggestionOngoing(false));
      }

      if (response.data.data.length < params.limit) {
        dispatch({
          type: actionLabels.SET_DROPDOWN_SUGGESTION_LOAD_MORE,
          payload: false,
        });
      }
    }
  } catch (e) {
    console.error(e);
    dispatch(setMentionSuggestionOngoing(false));
    dispatch(
      showSnackbar({
        message: 'Unable to fetch user list. Please try again!',
        severity: 'error',
      })
    );
  }
};

export const saveAvatarRequest = (payload) => async (dispatch) => {
  const bodyFormData = new FormData();
  bodyFormData.append('image', payload.image);
  bodyFormData.append('ext', payload.ext);

  try {
    const response = await axiosApiCaller(apiURLs.saveAvatar, 'POST', bodyFormData);
    if (response.status === 200) {
      if (getTorumToken()) {
        dispatch(getTorumUserProfileRequest());
      }
      return Promise.resolve(response.data.data.url);
    }
  } catch (e) {
    console.error(e);
    dispatch(
      showSnackbar({
        message: 'Something went wrong. Please try again!',
        severity: 'error',
      })
    );
    return Promise.reject();
  }
};

// save as profilePic as well as set the respective verified badge
export const setVerifiedAvatarBadgeRequest = (payload) => async (dispatch) => {
  const bodyFormData = new FormData();
  bodyFormData.append('image', payload.croppedImage);
  bodyFormData.append('ext', payload.ext);

  try {
    const response = await axiosApiCaller(apiURLs.setVerifiedAvatarBadge, 'POST', bodyFormData);

    if (response.status === 200) {
      dispatch(
        showSnackbar({
          message: 'Avatar Saved As Profile Pic Successfully!',
          severity: 'success',
        })
      );
    }
  } catch (e) {
    console.error(e);
    dispatch(
      showSnackbar({
        message: 'Something went wrong. Please try again!',
        severity: 'error',
      })
    );
  }
};
