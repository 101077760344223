import { useWeb3React } from '@web3-react/core';

import useContract from '../useContract';

import NFT_PROXY_ABI from '../../contracts/abis/NFT2.0/NFT_2.json';
import { BSC_TESTNET_CHAIN_ID } from '../../constants/enums';

const useNFT2Contract = () => {
  const { chainId } = useWeb3React();
  return useContract(
    chainId === BSC_TESTNET_CHAIN_ID ? '0x781ab40BDF3F6765a5780323a698f3abF442fb2a' : '',
    NFT_PROXY_ABI,
    false
  );
};

export default useNFT2Contract;
