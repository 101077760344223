import { InjectedConnector } from '@web3-react/injected-connector';
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { BscConnector } from '@binance-chain/bsc-connector';
import { SUPPORTED_CHAIN_IDS } from '../config';
// import WalletConnectProvider from '@maticnetwork/walletconnect-provider';

// const POLLING_INTERVAL = process.env.REACT_APP_POLLING_INTERVAL;
// const RPC_URLS = {
//   1: process.env.REACT_APP_RPC_URL_1,
//   42: process.env.REACT_APP_RPC_URL_42,
// };

// Add different connectors
export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS, // Change according to supported Network Ids
});

// export const walletconnect = new WalletConnectConnector({
//   rpc: { 1: RPC_URLS[1] },
//   bridge: 'https://bridge.walletconnect.org',
//   qrcode: true,
//   pollingInterval: POLLING_INTERVAL,
// });

export const bsc = new BscConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS, // later on 1 ethereum mainnet and 3 ethereum ropsten will be supported
});

// export const maticProvider = new WalletConnectProvider({
//   host: 'https://rpc-mumbai.matic.today',
//   callbacks: {
//     onConnect: () => {},
//     onDisconnect: () => {},
//   },
// });
