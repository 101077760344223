/* eslint-disable import/prefer-default-export */
import { all, takeEvery, put, call, select } from 'redux-saga/effects';

import axiosApiCaller from '../../../http/axios/apiCaller';
import axiosFormdata from '../../../http/axios/axiosFormdata';
import apiURLs from '../../../utils/apiURLs';
import * as actionLabels from '../../actionLabels';
import {
  logoutUserRequest,
  logoutUserSuccess,
  logoutUserFail,
  loginWithTorumRequest,
  loginWithTorumSuccess,
  loginWithTorumFail,
  getTorumUserProfileSuccess,
  getTorumUserProfileFail,
  updateTorumUserProfileSuccess,
  updateTorumUserProfileFail,
  updateImageSuccess,
  updateImageFail,
  updateCoverImageSuccess,
  originalProfilePic,
  guestUserFail,
  setIsAuthOngoing,
  setIsGuest,
  showSnackbar,
  setShareOnTorumModal,
  setShareIntent,
  setLoginModal,
  setSyncToTorumModal,
} from '../../actions';
import { AVATAR_ACCESS_LEVEL_KEY, AVATAR_ACCESS_TOKEN_KEY, AVATAR_REFRESH_TOKEN_KEY } from '../../../enums';

// eslint-disable-next-line no-unused-vars

function* loginWithTorumRequestSaga({ payload }) {
  const { data } = payload;

  try {
    yield put(setIsAuthOngoing(true));
    const response = yield axiosApiCaller(apiURLs.login, 'POST', data);
    if (response.status === 200) {
      localStorage.clear();
      localStorage.setItem(AVATAR_ACCESS_TOKEN_KEY, response.data.data.access_token);
      localStorage.setItem(AVATAR_REFRESH_TOKEN_KEY, response.data.data.refreshToken);
      localStorage.setItem(AVATAR_ACCESS_LEVEL_KEY, response.data.data.accessLevel);
      yield put(
        loginWithTorumSuccess({
          user: response.data.data,
        })
      );

      yield put(setIsAuthOngoing(false));
      yield put(setSyncToTorumModal(false));

      // If user was guest earlier, switching off when they're logging in.
      // yield put(setIsGuest(false));

      yield put(
        showSnackbar({
          message: 'Welcome to Torum Avatar NFT!',
          severity: 'success',
        })
      );

      if (response.data.data.accessLevel === 3) {
        yield put(setSyncToTorumModal('changePassword'));
      } else {
        yield call(getUserTorumRequestSaga);
      }
    } else {
      yield put(setIsAuthOngoing(false));
      yield put(loginWithTorumFail());
      yield put(
        showSnackbar({
          message: 'Login Failed. Please check your credentials and try again!',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('error', error);
    yield put(loginWithTorumFail());
    yield put(setIsAuthOngoing(false));

    yield put(
      showSnackbar({
        message: 'Login Failed. Please check your credentials and try again!',
        severity: 'error',
      })
    );
  }
}

function* registerWithTorumRequestSaga({ payload }) {
  const { data } = payload;
  try {
    yield put(setIsAuthOngoing(true));
    const response = yield axiosApiCaller(apiURLs.updatePassword, 'POST', data);
    if (response.status === 200) {
      // localStorage.clear();
      localStorage.setItem(AVATAR_ACCESS_LEVEL_KEY, 1);

      yield put(setIsAuthOngoing(false));
      yield put(setSyncToTorumModal(false));

      yield call(getUserTorumRequestSaga);

      yield put(
        showSnackbar({
          message: 'Registration done successfully!',
          severity: 'success',
        })
      );
    } else {
      yield put(setIsAuthOngoing(false));
      yield put(loginWithTorumFail());
      yield put(
        showSnackbar({
          message: 'Registration Failed. ',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('error', error);
    yield put(loginWithTorumFail());
    yield put(setIsAuthOngoing(false));

    yield put(
      showSnackbar({
        message: 'Registration Failed. ',
        severity: 'error',
      })
    );
  }
}

function* getUserVerifySaga({ payload }) {
  const { data } = payload;
  try {
    yield put(setIsAuthOngoing(true));
    const response = yield axiosApiCaller(apiURLs.verifyUser, 'POST', data);
    if (response.status === 200) {
      localStorage.clear();
      localStorage.setItem(AVATAR_ACCESS_TOKEN_KEY, response?.data?.data);
      localStorage.setItem(AVATAR_ACCESS_LEVEL_KEY, 3);
      yield put(setSyncToTorumModal('changePassword'));

      yield put(setIsAuthOngoing(false));
    } else {
      yield put(setIsAuthOngoing(false));
      yield put(
        showSnackbar({
          message: 'Not Authenticated. ',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('error', error);
    yield put(loginWithTorumFail());
    yield put(setIsAuthOngoing(false));

    yield put(
      showSnackbar({
        message: 'Not Authenticated. ',
        severity: 'error',
      })
    );
  }
}

function* getUserTorumRequestSaga() {
  const shareIntent = yield select((state) => state.userReducer.shareIntent);

  try {
    const response = yield axiosApiCaller(apiURLs.userDetails, 'GET');

    if (response.status === 200) {
      yield put(
        getTorumUserProfileSuccess({
          user: response.data.data,
        })
      );

      // if the login was initiated from share on torum intent, then
      // get user back to what they originally intended to do which is ShareOnTorum Modal
      if (shareIntent) {
        yield put(setShareOnTorumModal(true));
        yield put(setShareIntent(false));
      }
    } else {
      yield put(getTorumUserProfileFail());
    }
  } catch (error) {
    // console.error(error);
    yield put(getTorumUserProfileFail());
  }
}

function* logoutUserRequestSaga({ payload }) {
  localStorage.clear();
  yield put(logoutUserSuccess());
}

function* updateTorumUserProfileRequestSaga({ payload }) {
  try {
    const { data, onEditProfileSuccess } = payload;

    const payloadWithUpdatedMeta = {
      ...data,
      meta: {
        ...data.meta,
        socialmediaLinks: data.socialmediaLinks,
        portfolioLink: data.portfolioLink,
      },
    };

    const response = yield axiosApiCaller(apiURLs.updateUserDetails, 'PATCH', payloadWithUpdatedMeta);

    if (response.status === 200) {
      // re-using this action
      yield put(
        getTorumUserProfileSuccess({
          user: response.data.data,
        })
      );
      onEditProfileSuccess();
    } else {
      yield put(updateTorumUserProfileFail());
    }
  } catch (error) {
    console.log('error', error);
    yield put(updateTorumUserProfileFail());
  }
}

// function* updateImageRequestSaga({ payload }) {
//   try {
//     let formData = new FormData(); //formdata object
//
//     Object.keys(payload).map((key) => {
//       formData.append(key, payload[key]);
//     });
//     const response = yield axiosApiCaller(apiURLs.uploadUserMedia, 'POST', formData);
//
//     if (response.status === 200) {
//       if (response.data.data.type === 'profile_pic' && payload.onlyOriginal) {
//         yield put(
//           originalProfilePic({
//             originalProfilePic: response.data.data.url,
//           })
//         );
//       } else if (response.data.data.type === 'profile_pic') {
//         yield put(
//           updateImageSuccess({
//             profilePic: response.data.data.url.original,
//           })
//         );
//       }
//     } else {
//       yield put(updateImageFail());
//     }
//   } catch (error) {
//     console.log('error', error);
//     yield put(updateImageFail());
//   }
// }

function* guestUserRequestSaga({ payload }) {
  try {
    yield put(setIsAuthOngoing(true));
    const response = yield axiosApiCaller(apiURLs.guestUser, 'POST', payload);

    if (response.status === 200) {
      // localStorage.clear();
      // localStorage.setItem('avatar_access_token', response.data.data.access_token);
      // yield put(
      //   guestUserSuccess({
      //     user: response.data.data,
      //   })
      // );
      // yield put(setIsGuest(true));
      yield put(setIsAuthOngoing(false));
      yield put(setSyncToTorumModal('torumLander'));

      yield put(
        showSnackbar({
          message: 'Check Your Email Inbox/Spam Folder For Torum Login Credentials',
          severity: 'success',
        })
      );
    } else {
      // yield put(guestUserFail());
      yield put(setIsAuthOngoing(false));

      yield put(
        showSnackbar({
          message: 'Something went wrong. Please try again!',
          severity: 'error',
        })
      );
    }
  } catch (error) {
    console.log('error', error);
    yield put(setIsAuthOngoing(false));
    // yield put(guestUserFail());
    if (error?.data?.error?.message) {
      yield put(
        showSnackbar({
          message: error?.data?.error?.message,
          severity: 'error',
        })
      );
    } else {
      yield put(
        showSnackbar({
          message: 'Something went wrong. Please try again!',
          severity: 'error',
        })
      );
    }
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actionLabels.LOGOUT_USER_REQUEST, logoutUserRequestSaga),
    yield takeEvery(actionLabels.GET_SYNCHRONIZE_USER_PROFILE_REQUEST, loginWithTorumRequestSaga),
    yield takeEvery(actionLabels.GET_SYNCHRONIZE_REGISTERED_USER_PROFILE_REQUEST, registerWithTorumRequestSaga),
    yield takeEvery(actionLabels.GET_USER_VERIFY, getUserVerifySaga),
    yield takeEvery(actionLabels.GET_TORUM_USER_PROFILE_REQUEST, getUserTorumRequestSaga),
    yield takeEvery(actionLabels.UPDATE_TORUM_USER_PROFILE_REQUEST, updateTorumUserProfileRequestSaga),
    // yield takeEvery(actionLabels.UPDATE_IMAGE_REQUEST, updateImageRequestSaga),
    yield takeEvery(actionLabels.GUEST_USER_REQUEST, guestUserRequestSaga),
  ]);
}
