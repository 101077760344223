import * as actionLabels from '../../actionLabels';

export const dummyAction = () => {
  return {
    type: actionLabels.DUMMY_ACTION_SAGA,
  };
};
export const dummyActionSuccess = () => {
  return {
    type: actionLabels.DUMMY_ACTION_SUCCESS,
  };
};

export const setThemeAction = (payload) => {
  return {
    type: actionLabels.SET_THEME,
    payload,
  };
};

export const setWalletConnectModal = (payload) => {
  return {
    type: actionLabels.SET_WALLET_CONNECT_MODAL,
    payload,
  };
};

export const setSelectedSeries = (payload) => {
  return {
    type: actionLabels.SET_SELECTED_SERIES,
    payload,
  };
};
