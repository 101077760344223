import { useWeb3React } from '@web3-react/core';

import useContract from '../useContract';

import NFT_PROXY_ABI from '../../contracts/abis/NFT2.0/NFT_1.json';
import { BSC_TESTNET_CHAIN_ID } from '../../constants/enums';

const useNFT1Contract = () => {
  const { chainId } = useWeb3React();
  return useContract(
    chainId === BSC_TESTNET_CHAIN_ID ? '0x369b3c4dAD4DA76C649103C5E1508ff68BceB619' : '',
    NFT_PROXY_ABI,
    false
  );
};

export default useNFT1Contract;
