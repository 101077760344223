import React from 'react';

import './Spinner.scss';

const Spinner = (props) => {
  const { withoutMargin } = props;
  return (
    <>
      <div className={`loader ${withoutMargin ? 'without-margin' : ''}`} />
      <div className='modal-backdrop' />
    </>
  );
};

export default Spinner;
