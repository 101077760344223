export let MASTER_PROXY_ADDRESS = '0x12B495ED966e2A745C82e39ca17c849e07AD3b60';
export let NFT_PROXY_ADDRESS = '0x092a262163fb7B400cFdDe326fC75f9A972069E4';
export let TORUM_TOKEN_ADDRESS = '0xcd1faff6e578fa5cac469d2418c95671ba1a62fe';
export let BLINDBOX_OWNER_ADDRESS = '0x50367Ba4d00C760421d29160D084c55159a2b833';
export let SERIES_GENESIS_TOKEN_ID = 127;
export let SERIES_SQUID_GAME_TOKEN_ID = 641;
export let SUPPORTED_CHAIN_IDS = [56]; // 56 is mainnet

export let XXX = Array.from({ length: SERIES_SQUID_GAME_TOKEN_ID }, (_, i) => {
  if ([SERIES_GENESIS_TOKEN_ID, SERIES_SQUID_GAME_TOKEN_ID].includes(i + 1)) return null;
  return i + 1;
}).filter(Boolean);

if (process.env.REACT_APP_ENV === 'development') {
  MASTER_PROXY_ADDRESS = '0xe198e56F4b3Ad13ebEac56d93deF2CF61625D8fF'; // blindbox/spender
  NFT_PROXY_ADDRESS = '0xb1a98F55047D9b197183C570fF981623BF50a565'; // nft contract
  TORUM_TOKEN_ADDRESS = '0x54d7dea1eb1e957fd4210fad44b47ed6b47a8d73'; // xtm token
  BLINDBOX_OWNER_ADDRESS = '0xd59AF98e9B8885829aa5924E482549e2c24A50B9';

  SERIES_GENESIS_TOKEN_ID = 127;
  SERIES_SQUID_GAME_TOKEN_ID = 641;
  SUPPORTED_CHAIN_IDS = [56, 97];
}

// console.log(process.env.REACT_APP_ENV, 'env');

// note:
// series 1 has nft Ids from 1 to 126
// series 2 has nft Ids from 128 to 640 (total: 513)

// 0xAe9207eeF905750862Feb01e522cA3EE86b8Ed11