import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import MarketplaceModal from '../../components/Modal/Modal';

// actions
import { registerWithTorumRequest } from '../../store/actions';

// assets
import { ReactComponent as BackIcon } from '../../assets/images/svg/back.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/cancel-button.svg';

const ChangePasswordModal = ({ show, onClose, children, setShowModal, goBack, showSnackbarMessage }) => {
  const [userDetails, setUserDetails] = useState({ email: '', confirmPassword: '' });
  const [password, setPassword] = useState({
    newPassword: '',
    submitted: {
      password: false,
    },
    validity: {
      newPassword: false,
      confirmPassword: false,
    },
  });

  const onNewPasswordChange = (e) => {
    console.log('inise new', e);
    const newValidity = e.target.value.length >= 8;
    const newValue = e.target.value;

    setPassword((prevState) => ({
      ...password,
      newPassword: newValue,
      validity: {
        ...prevState.validity,
        newPassword: newValidity,
      },
    }));
  };

  const onConfirmPasswordChange = (e) => {
    console.log('inise confirm', e);
    const newValidity = e.target.value === password.newPassword;
    const newValue = e.target.value;

    setPassword((prevState) => ({
      ...password,
      confirmPassword: newValue,
      validity: {
        ...prevState.validity,
        confirmPassword: newValidity,
      },
    }));
  };

  const isAuthOngoing = useSelector((state) => state.userReducer.isAuthOngoing);

  const dispatch = useDispatch();

  const onSubmitLogin = () => {
    console.log(password);
    if (password.validity.newPassword && password.validity.confirmPassword) {
      const params = {
        new_password: password.newPassword,
      };

      dispatch(registerWithTorumRequest({ data: params }));
    } else {
      setPassword({
        ...password,
        submitted: {
          password: true,
        },
      });
    }
  };
  console.log('password', password);
  return (
    <MarketplaceModal show={show} onClose={goBack}>
      <div className='synchronize-wallet-container'>
        <div className='synchronize-wallet-title-container-subcontainer'>
          <div className='synchronize-wallet-title-container-heading'>Complete Your Registration</div>
          <div className='synchronize-wallet-title-container-subheading'>
            You're one step away from claiming your account. Set up a proper password and you're good to go!
          </div>
        </div>
        <div className='synchronize-wallet-update-password-input'>
          <input
            type='password'
            name='password'
            placeholder='Password'
            className={`form-control input_border ${
              password?.submitted?.password && !password?.validity?.newPassword && 'invalid'
            }`}
            value={password?.newPassword}
            onChange={onNewPasswordChange}
          />
        </div>
        <div className='synchronize-wallet-update-password-input'>
          <input
            type='password'
            name='confirmPassword'
            placeholder='Confirm Password'
            className={`form-control input_border ${
              password?.submitted?.password && !password?.validity?.confirmPassword && 'invalid'
            }`}
            value={password?.confirmPassword}
            onChange={onConfirmPasswordChange}
          />
        </div>

        <button disabled={isAuthOngoing} className='synchronize-wallet-login-button' onClick={onSubmitLogin}>
          {isAuthOngoing ? 'REGISTERING YOU IN...' : 'REGISTER AND SYNCHRONIZE'}
        </button>
      </div>
    </MarketplaceModal>
  );
};

export default ChangePasswordModal;
