import React, { Fragment, useEffect, useState } from 'react';

import SideBar from '../../components/SideBar/SideBar';

import './AppLayout.scss';

const AppLayout = ({ children }) => {
  const [theme, setTheme] = useState('dark');

  return (
    <Fragment>
      <div className={`appLayout ${theme}`}>
        <div className='fixed-sidebar'>
          <SideBar onThemeToggle={theme === 'dark' ? () => setTheme('light') : () => setTheme('dark')} />
        </div>
        <div id='content' className={`oy-auto`}>
          <div className='main-content'>
            <nav className='right-container'>{children}</nav>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppLayout;
