export * from './dummy/dummy';
export * from './user/user';
export * from './snackbar/snackbar';

export const resetApp = (payload) => {
  return {
    type: 'RESET_APP',
    payload,
  };
};
