import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useWallet } from 'use-wallet';
import { useDispatch } from 'react-redux';
import { resetApp } from '../store/actions';

const useUserAccount = () => {
  const [account, setAccount] = useState(null);
  const [currentLibrary, setCurrentLibrary] = useState(null);
  const dispatch = useDispatch();
  const { account: metamaskAccount, active, error, library } = useWeb3React();
  const { account: bscAccount, status, ethereum, connector } = useWallet();

  useEffect(() => {
    const sessionAccount = sessionStorage.getItem('account');

    if (metamaskAccount && metamaskAccount !== sessionAccount) {
      sessionStorage.setItem('account', metamaskAccount);
      dispatch(resetApp());
    }
    if (bscAccount && bscAccount !== sessionAccount) {
      sessionStorage.setItem('account', bscAccount);
      dispatch(resetApp());
    }

    if (active && metamaskAccount) {
      setAccount(metamaskAccount);
      setCurrentLibrary(library);
    }

    if (bscAccount && status === 'connected') {
      setAccount(bscAccount);
      setCurrentLibrary(ethereum);
    }

    if (!metamaskAccount && !bscAccount) {
      setAccount(null);
      setCurrentLibrary(null);
    }
  }, [active, error, account, bscAccount, status, metamaskAccount, ethereum, library]);

  return { account, currentLibrary, isBsc: connector === 'bsc' };
};

export default useUserAccount;
