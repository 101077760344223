import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { Web3ReactProvider } from '@web3-react/core';
import { UserRejectedRequestError } from '@binance-chain/bsc-connector';
import { ConnectionRejectedError, UseWalletProvider } from 'use-wallet';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import App from './App';
import history from './utils/history';
import * as serviceWorker from './serviceWorker';
import getLibrary from './utils/getLibrary';
import rootReducer from './store/reducer';
import rootSaga from './store/sagas';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/styles.scss';
import './assets/styles/zStyles.scss';
import './index.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import { bsc } from './utils/connectors';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  (process.env.REACT_APP_ENV === 'development' &&
    typeof window !== 'undefined' &&
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const sagaMiddleware = createSagaMiddleware();

// exported this because we needed to access dispatch function in apiCaller
export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk, sagaMiddleware)));
// initStateWithPrevTab(store)
let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate onBeforeLift={() => {}} persistor={persistor}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <UseWalletProvider
            connectors={{
              bsc: {
                web3ReactConnector() {
                  return bsc;
                },
                handleActivationError(err) {
                  if (err instanceof UserRejectedRequestError) {
                    return new ConnectionRejectedError();
                  }
                },
              },
            }}
          >
            <Router history={history}>
              <App />
            </Router>
          </UseWalletProvider>
        </Web3ReactProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
