import * as actionLabels from '../../actionLabels';

const initialState = {
  dropdownSuggestionLoadMore: true,
  getMentionsOngoing: false,
  mentions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionLabels.SET_DROPDOWN_SUGGESTION_LOAD_MORE:
      return {
        ...state,
        dropdownSuggestionLoadMore: action.payload,
      };
    case actionLabels.SET_MENTION_SUGGESTION_ONGOING:
      return {
        ...state,
        getMentionsOngoing: action.payload,
      };
    case actionLabels.SET_MENTION_SUGGESTION_SUCCESS:
      return {
        ...state,
        mentions: action.payload,
      };
    case actionLabels.SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case actionLabels.SET_MENTIONS_LIST:
      const fetchedMentions = state.mentions.filter((mention) => mention._id !== 321);
      return {
        ...state,
        mentions: [action.payload, ...fetchedMentions],
      };
    case actionLabels.RESET_MENTIONS_LIST:
      const filteredMention = state.mentions.filter((mention) => mention._id === 321);
      return {
        ...state,
        mentions: [...filteredMention],
      };
    case actionLabels.RESET_EDITOR_REDUCER:
      return {
        ...state,
      };
    default:
      return state;
  }
};
