import { useWeb3React } from '@web3-react/core';

import useContract from './useContract';

import TORUM_TOKEN_ABI from '../contracts/abis/TORUM_TOKEN_ABI.json';
import { BSC_MAINNET_CHAIN_ID, BSC_TESTNET_CHAIN_ID } from '../constants/enums';
import { TORUM_TOKEN_ADDRESS } from '../config';

const useTorumTokenContract = () => {
  const { chainId } = useWeb3React();

  return useContract(
    chainId === BSC_TESTNET_CHAIN_ID || chainId === BSC_MAINNET_CHAIN_ID ? TORUM_TOKEN_ADDRESS : '0x0000000000000000000000000000000000000000',
    TORUM_TOKEN_ABI,
    true
  );
};

export default useTorumTokenContract;
