import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import MarketplaceModal from '../../components/Modal/Modal';

// actions
import { guestUserRequest } from '../../store/actions';

// assets
import { ReactComponent as BackIcon } from '../../assets/images/svg/back.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/cancel-button.svg';

const GuestUserModal = ({ show, onClose, children, setShowModal, goBack, showSnackbarMessage }) => {
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const isAuthOngoing = useSelector((state) => state.userReducer.isAuthOngoing);

  const dispatch = useDispatch();
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleOnChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const onSubmitLogin = () => {
    validateEmail(email) ? dispatch(guestUserRequest({ email })) : setEmailError(true);
  };

  return (
    <MarketplaceModal show={show} onClose={goBack}>
      <div className='synchronize-wallet-container'>
        <div className='synchronize-wallet-title-container'>
          <span>
            <BackIcon className='synchronize-wallet-back-icon' onClick={goBack} />
            <span>Continue as Guest</span>
          </span>
          <CloseIcon className='synchronize-wallet-close-icon' onClick={goBack} />
        </div>
        <div className='synchronize-wallet-login-description'>
          Do a quick registration for a temporary Torum account to interact with Torum NFT Marketplace. You can claim
          your account anytime.
        </div>

        <div className='synchronize-wallet-email-input'>
          <input
            type='text'
            name='email'
            placeholder='Email Address'
            className={`form-control input_border ${emailError && 'invalid'}`}
            onChange={handleOnChange}
          />
        </div>

        <div className='synchronize-wallet-proceed-button'>
          <button onClick={onSubmitLogin} disabled={isAuthOngoing}>
            {isAuthOngoing ? 'LOGGING YOU IN' : 'PROCEED'}
          </button>
        </div>

        <div className='synchronize-wallet-signup-text-container'>
          <a href='https://www.torum.com/signup' target='_blank' rel='noopener noreferrer'>
            Sign Up
          </a>
          <span className='synchronize-wallet-signup-guest-text'>for an account instead</span>
        </div>
      </div>
    </MarketplaceModal>
  );
};

export default GuestUserModal;
