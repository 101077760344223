import { useMemo } from 'react';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';
import { Contract } from '@ethersproject/contracts';

// account is not optional
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library, account = '') {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(address, ABI, library, account = '') {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

const useContract = (address = undefined, ABI, withSignerIfPossible = true) => {
  const { library, account, chainId, connector } = useWeb3React();
  const { ethereum, BinanceChain } = window;
  window.web3 = new Web3(!!chainId ? ethereum : BinanceChain);
  window.web3.eth.defaultAccount = account;

  return useMemo(() => {
    if (!address || !ABI) return null;
    try {
      const contractInstance = new window.web3.eth.Contract(ABI, address);
      return contractInstance;
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, []);
};

export default useContract;
