export const GET_SYNCHRONIZE_USER_PROFILE_REQUEST = 'GET_SYNCHRONIZE_USER_PROFILE_REQUEST';
export const GET_SYNCHRONIZE_REGISTERED_USER_PROFILE_REQUEST = 'GET_SYNCHRONIZE_REGISTERED_USER_PROFILE_REQUEST';
export const GET_USER_VERIFY = 'GET_USER_VERIFY';
export const GET_SYNCHRONIZE_USER_PROFILE_SUCCESS = 'GET_SYNCHRONIZE_USER_PROFILE_SUCCESS';
export const GET_SYNCHRONIZE_USER_PROFILE_FAIL = 'GET_SYNCHRONIZE_USER_PROFILE_FAIL';

export const UPDATE_USER_PROFILE_IMAGE_REQUEST = 'UPDATE_USER_PROFILE_IMAGE_REQUEST';
export const UPDATE_USER_PROFILE_IMAGE_SUCCESS = 'UPDATE_USER_PROFILE_IMAGE_SUCCESS';
export const UPDATE_USER_PROFILE_IMAGE_FAIL = 'UPDATE_USER_PROFILE_IMAGE_FAIL';

// Torum
export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS ';

export const GET_TORUM_USER_PROFILE_REQUEST = 'GET_TORUM_USER_PROFILE_REQUEST';
export const GET_TORUM_USER_PROFILE_SUCCESS = 'GET_TORUM_USER_PROFILE_SUCCESS';
export const GET_TORUM_USER_PROFILE_FAIL = 'GET_TORUM_USER_PROFILE_FAIL';

export const UPDATE_TORUM_USER_PROFILE_REQUEST = 'UPDATE_TORUM_USER_PROFILE_REQUEST';
export const UPDATE_TORUM_USER_PROFILE_SUCCESS = 'UPDATE_TORUM_USER_PROFILE_SUCCESS';
export const UPDATE_TORUM_USER_PROFILE_FAIL = 'UPDATE_TORUM_USER_PROFILE_FAIL';

export const UPDATE_IMAGE_REQUEST = 'UPDATE_IMAGE_REQUEST';
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS';
export const UPDATE_IMAGE_FAIL = 'UPDATE_IMAGE_FAIL';

export const UPDATE_COVER_IMAGE_SUCCESS = 'UPDATE_COVER_IMAGE_SUCCESS';

export const ORIGINAL_PROFILE_PIC = 'ORIGINAL_PROFILE_PIC';

export const GUEST_USER_REQUEST = 'GUEST_USER_REQUEST';
export const GUEST_USER_SUCCESS = 'GUEST_USER_SUCCESS';
export const GUEST_USER_FAIL = 'GUEST_USER_FAIL';

export const AUTH_ONGOING = 'AUTH_ONGOING';
export const SET_IS_GUEST = 'SET_IS_GUEST';

export const SET_LOGIN_MODAL = 'SET_LOGIN_MODAL';
export const SET_SYNC_TO_TORUM_MODAL = 'SET_SYNC_TO_TORUM_MODAL';
export const SET_SHOW_SHARE_ON_TORUM_MODAL = 'SET_SHOW_SHARE_ON_TORUM_MODAL';
export const SET_SHARE_INTENT = 'SET_SHARE_INTENT';

export const SET_POST_MEDIA_UPLOAD_ONGOING = 'SET_POST_MEDIA_UPLOAD_ONGOING';
export const SET_CREATE_POST_ONGOING = 'SET_CREATE_POST_ONGOING';
