import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { clearSnackbar, showSnackbar } from '../../store/actions';

export const SnackbarNotification = () => {
  const dispatch = useDispatch();
  const snackbarState = useSelector((state) => state.snackbarReducer);

  const showMessage = (message, severity = 'info', duration = 3000) => {
    dispatch(
      showSnackbar({
        message,
        severity,
        duration: duration,
      })
    );
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={snackbarState.duration}
      open={snackbarState.open}
      onClose={handleClose}
      TransitionComponent={Slide}
    >
      <Alert variant='filled' onClose={handleClose} severity={snackbarState.severity}>
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
};
