import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import './modal.scss';

const MarketplaceModal = ({ show, onClose, children, modalBody }) => {
  const { theme } = useSelector((state) => state.dummyReducer);

  return (
    <Modal
      className={`modal fade err nftmarketplace-modal ${theme}`}
      id=''
      show={show}
      onHide={onClose}
      keyboard={false}
    >
      <Modal.Body className={modalBody}>
        <div>{children}</div>
      </Modal.Body>
    </Modal>
  );
};

export default MarketplaceModal;
