// constants used across multiple components kept here

// CreateNFT constants
export const STATUS_INITIAL = 'pending';
export const STATUS_ONGOING = 'ongoing';
export const STATUS_COMPLETED = 'done';
export const STATUS_FAILED = 'failed';

export const PRICE_SYMBOLS = [
  { value: 'XTM', label: 'XTM' },
  { value: 'BNB', label: 'BNB' },
  { value: 'HT', label: 'HT' },
];

export const BSC_TESTNET_CHAIN_ID = 97;
export const BSC_MAINNET_CHAIN_ID = 56;
