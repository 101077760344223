import React from 'react';
import axios from 'axios';
import HmacSHA256 from 'crypto-js/hmac-sha256';

import apiURLs from '../../utils/apiURLs';
import { store } from '../../index';
import { showInfoSnackbar } from '../../store/actions';
import { AVATAR_ACCESS_TOKEN_KEY, AVATAR_REFRESH_TOKEN_KEY, ERRORS } from '../../enums';

const clearStorageWithDelay = () => {
  localStorage.clear();
  window.location.reload();
};

const apiCaller = (url, method = 'POST', data, params, action, cancelToken) => {
  const time = Math.floor(new Date() / 1000);

  let x = '';

  if (process.env.REACT_APP_ENV === 'development') {
    x = 'secret';
  } else {
    x = 'f0P7FsVFrvBeJTvD';
  }

  // x = 'f0P7FsVFrvBeJTvD';

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;

      if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(AVATAR_REFRESH_TOKEN_KEY);

        return axios.get(apiURLs.refreshToken).then((res) => {
          if (res.status === 200) {
            localStorage.setItem(AVATAR_ACCESS_TOKEN_KEY, res.data.data.access_token);
            localStorage.setItem(AVATAR_REFRESH_TOKEN_KEY, res.data.data.refreshToken);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.data.access_token;
            window.location.reload();
            return axios(originalRequest);
          }
        });
      }

      if (error.response.status === 401) {
        store.dispatch(showInfoSnackbar(ERRORS.SESSION_EXPIRED));
        setTimeout(() => clearStorageWithDelay(), 1500);
      }

      return Promise.reject(error);
    }
  );

  return axios({
    url,
    method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AVATAR_ACCESS_TOKEN_KEY)}`,
      hmac: HmacSHA256((localStorage.getItem(AVATAR_ACCESS_TOKEN_KEY) + time).toString(), x).toString(),
      t: window.btoa(time),
    },
    data: data,
    params: params,
    cancelToken: cancelToken,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export default apiCaller;
