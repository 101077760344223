import * as actionLabels from '../../actionLabels';

const initialState = {
  action: false,
  theme: 'dark',
  showWalletConnectModal: false,
  selectedSeries: 'squidGame',
};

// This will be later renamed to headerReducer
// We'll keep only header related state here and maybe others that can't
// be kept in any other reducers. So, this will be kind of generalReducer

export default (state = initialState, action) => {
  switch (action.type) {
    case actionLabels.DUMMY_ACTION_SUCCESS:
      return {
        ...state,
        action: true,
      };
    case actionLabels.SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case actionLabels.SET_WALLET_CONNECT_MODAL:
      return {
        ...state,
        showWalletConnectModal: action.payload,
      };
    case actionLabels.SET_SELECTED_SERIES:
      return {
        ...state,
        selectedSeries: action.payload,
      };
    default:
      return state;
  }
};
