import * as actionLabels from '../../actionLabels';

export const showSnackbar = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionLabels.SNACKBAR_SHOW,
      payload,
    });
  };
};

export const clearSnackbar = () => {
  return (dispatch) => {
    dispatch({ type: actionLabels.SNACKBAR_CLEAR });
  };
};

export const showErrorSnackbar = (message) => {
  return {
    type: actionLabels.SNACKBAR_SHOW,
    payload: {
      message: message,
      severity: 'error',
    },
  };
};

export const showSuccessSnackbar = (message) => {
  return {
    type: actionLabels.SNACKBAR_SHOW,
    payload: {
      message: message,
      severity: 'success',
    },
  };
};

export const showInfoSnackbar = (message) => {
  return {
    type: actionLabels.SNACKBAR_SHOW,
    payload: {
      message: message,
      severity: 'info',
    },
  };
};

export const showWarningSnackbar = (message) => {
  return {
    type: actionLabels.SNACKBAR_SHOW,
    payload: {
      message: message,
      severity: 'warning',
    },
  };
};
