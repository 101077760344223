import * as actionLabels from '../../actionLabels';

const initialState = {
  user: {},
  isGuest: false,
  originalProfilePic: null,
  isAuthOngoing: false,
  showLoginModal: false,
  showSyncToTorumModal: false,
  showShareOnTorumModal: false,
  shareIntent: false,
  isPostMediaUploading: false,
  isCreatePostOngoing: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionLabels.GET_TORUM_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          userName: action.payload.user.username,
          name: action.payload.user.name,
          avatarImage: action.payload.user.avatarImage,
          coverPic: action.payload.user.cover_pic,
          profilePic: action.payload.user.profile_pic?.p140,
          bio: action.payload.user.bio,
          followers_count: action.payload.user.followers_count,
          following_count: action.payload.user.following_count,
          meta: action.payload.user.meta,
          _id: action.payload.user._id,
          nft: action.payload.user.nft,
        },
      };
    }
    case actionLabels.AUTH_ONGOING: {
      return {
        ...state,
        isAuthOngoing: action.payload,
      };
    }
    case actionLabels.UPDATE_USER_PROFILE_IMAGE_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case actionLabels.UPDATE_USER_PROFILE_IMAGE_FAIL: {
      return {
        ...state,
        user: {},
      };
    }
    case actionLabels.LOGOUT_USER_SUCCESS: {
      return {
        ...state,
        user: {},
        isGuest: false,
      };
    }
    case actionLabels.UPDATE_IMAGE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          profilePic: action.payload.profilePic,
        },
      };
    }
    case actionLabels.UPDATE_COVER_IMAGE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          coverPic: action.payload.coverPic,
        },
      };
    }
    case actionLabels.ORIGINAL_PROFILE_PIC: {
      return {
        ...state,
        originalProfilePic: action.payload.originalProfilePic,
      };
    }
    case actionLabels.SET_IS_GUEST: {
      return {
        ...state,
        isGuest: action.payload,
      };
    }
    case actionLabels.SET_LOGIN_MODAL: {
      return {
        ...state,
        showLoginModal: action.payload,
      };
    }
    case actionLabels.SET_SYNC_TO_TORUM_MODAL: {
      return {
        ...state,
        showSyncToTorumModal: action.payload,
      };
    }
    case actionLabels.SET_SHOW_SHARE_ON_TORUM_MODAL: {
      return {
        ...state,
        showShareOnTorumModal: action.payload,
      };
    }
    case actionLabels.SET_SHARE_INTENT: {
      return {
        ...state,
        shareIntent: action.payload,
      };
    }
    case actionLabels.SET_CREATE_POST_ONGOING: {
      return {
        ...state,
        isCreatePostOngoing: action.payload,
      };
    }
    default:
      return state;
  }
};
