import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import MarketplaceModal from '../../components/Modal/Modal';

// actions
import { loginWithTorumRequest } from '../../store/actions';

// assets
import { ReactComponent as BackIcon } from '../../assets/images/svg/back.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/cancel-button.svg';

const LoginAndSynchronizationModal = ({ show, onClose, children, setShowModal, goBack, showSnackbarMessage }) => {
  const [userDetails, setUserDetails] = useState({ email: '', password: '' });
  const isAuthOngoing = useSelector((state) => state.userReducer.isAuthOngoing);

  const dispatch = useDispatch();

  const handleOnChange = (event) => {
    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitLogin = () => {
    dispatch(loginWithTorumRequest({ data: userDetails }));
  };

  return (
    <MarketplaceModal show={show} onClose={goBack}>
      <div className='synchronize-wallet-container'>
        <div className='synchronize-wallet-title-container'>
          <span>
            <BackIcon className='synchronize-wallet-back-icon' onClick={goBack} />
            <span>Login to Torum</span>
          </span>
          <CloseIcon className='synchronize-wallet-close-icon' onClick={goBack} />
        </div>
        <div className='synchronize-wallet-login-description'>
          Synchronize your Torum account with your wallet address to unlock perks and benefits!
        </div>
        <div className='synchronize-wallet-login-points'>
          🙌
          <span className=''>Unlock social elements</span>
        </div>
        <div className='synchronize-wallet-login-points'>
          ✨<span className=''>Showcase your collectibles simultaneously</span>
        </div>
        <div className='synchronize-wallet-login-points'>
          🎨
          <span className=''>Exhibit your creations effectively</span>
        </div>
        <div className='synchronize-wallet-login-points'>
          👊
          <span className=''>Maintain your fanbase at once</span>
        </div>
        <div className='synchronize-wallet-login-points'>
          ✅<span className=''>Better chance to get verified</span>
        </div>
        <div className='synchronize-wallet-email-input'>
          <input
            type='text'
            name='email'
            placeholder='Username'
            className='form-control input_border'
            onChange={handleOnChange}
          />
        </div>
        <div className='synchronize-wallet-password-input'>
          <input
            type='password'
            name='password'
            placeholder='Password'
            className='form-control input_border'
            onChange={handleOnChange}
          />
        </div>

        <button disabled={isAuthOngoing} className='synchronize-wallet-login-button' onClick={onSubmitLogin}>
          {isAuthOngoing ? 'LOGGING YOU IN...' : 'LOGIN AND SYNCHRONIZE'}
        </button>

        <button className='synchronize-wallet-cancel-button' onClick={goBack}>
          CANCEL
        </button>

        <div className='synchronize-wallet-signup-text-container'>
          <span className='synchronize-wallet-signup-text'>Haven't registered an account?</span>
          <a href='https://www.torum.com/signup' target='_blank' rel='noopener noreferrer'>
            &nbsp; Sign Up here
          </a>
        </div>
      </div>
    </MarketplaceModal>
  );
};

export default LoginAndSynchronizationModal;
