import axios from 'axios';

// NOT IN USE
const axiosFormdata = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === 'development'
      ? `${process.env.REACT_APP_END_POINT_URL_DEV}/api/v1`
      : `${process.env.REACT_APP_END_POINT_URL_PROD}/api/v1`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
export default axiosFormdata;
