import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// components
import MarketplaceModal from '../../components/Modal/Modal';

// assets
import { ReactComponent as UserIcon } from '../../assets/images/svg/user.svg';
import { ReactComponent as GuestIcon } from '../../assets/images/svg/guest.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/svg/cancel-button.svg';

import './SynchronizeWalletModal.scss';

const SynchronizeWalletModal = ({ show, onClose, children, setShowModal, onProceedHandle }) => {
  const [userType, setUserType] = useState('torumLander');
  const shareIntent = useSelector((state) => state.userReducer.shareIntent);

  const onUserTypeClick = (data) => {
    setUserType(data);
  };

  const onProceedFormHandle = () => {
    if (userType === 'torumLander') {
      onProceedHandle('torumLander');
    } else {
      onProceedHandle('newcomer');
    }
  };

  return (
    <MarketplaceModal show={show} onClose={() => setShowModal(false)}>
      <div className='synchronize-wallet-container'>
        <div className='synchronize-wallet-title-container'>
          <span>
            {/*<BackIcon className='synchronize-wallet-back-icon' onClick={() => {}} />*/}
            <span>{shareIntent ? 'Login To Torum To Share' : 'Synchronize Your Wallet'}</span>
          </span>
          <CloseIcon className='synchronize-wallet-close-icon' onClick={() => setShowModal(false)} />
        </div>
        <div className='synchronize-wallet-user-guest-container'>
          <div
            className={`syncronize-wallet-user-guest-type ${userType === 'torumLander' ? 'active' : 'unactive'}`}
            onClick={() => onUserTypeClick('torumLander')}
          >
            <UserIcon className='syncronize-wallet-user-guest-image' />
            <div className='syncronize-wallet-user-guest-text'>I'm a Torum Lander</div>
          </div>
          <div
            className={`syncronize-wallet-user-guest-type ${userType === 'newcomer' ? 'active' : 'unactive'}`}
            onClick={() => onUserTypeClick('newcomer')}
            // onClick={() => {}}
            // style={{ cursor: 'not-allowed' }}
          >
            <GuestIcon className='syncronize-wallet-user-guest-image' />
            <div className='syncronize-wallet-user-guest-text'>I'm a newcomer</div>
          </div>
        </div>
        {userType === 'torumLander' ? (
          <div className='synchronize-wallet-user-guest-description'>You already have an existing Torum account.</div>
        ) : (
          <div className='synchronize-wallet-user-guest-description'>Howdy and welcome!</div>
        )}
        <div className='synchronize-wallet-proceed-button'>
          <button onClick={onProceedFormHandle}>PROCEED</button>
        </div>
      </div>
    </MarketplaceModal>
  );
};

export default SynchronizeWalletModal;
