let BASE_URL = '';
let USER_URL = '';
let POST_URL = '';
let SOCKET_URL = '';
let GUEST_URL = '';

if (process.env.REACT_APP_ENV === 'development') {
  BASE_URL = 'https://auth.api.torum.team/api/';
  USER_URL = 'https://user.api.torum.team/api/';
  POST_URL = 'https://post.api.torum.team/api/';
  SOCKET_URL = 'https://websocket.api.torum.team';
  GUEST_URL = 'https://auth.api.torum.team/api/';
} else {
  BASE_URL = 'https://reauth.api.torum.com/api/';
  USER_URL = 'https://reuser.api.torum.com/api/';
  POST_URL = 'https://repost.api.torum.com/api/';
  SOCKET_URL = 'https://rewebsocket.api.torum.team';
  GUEST_URL = 'https://auth.api.torum.team/api/';
}

export default {
  socketUrl: `${SOCKET_URL}`,
  login: `${BASE_URL}auth/login`,
  userDetails: `${USER_URL}user`,
  refreshToken: `${BASE_URL}auth/token`,
  updateUserDetails: `${USER_URL}user/update`,
  uploadUserMedia: `${USER_URL}user/image/store`,
  uploadPostMedia: `${POST_URL}post/media/store`,

  guestUser: `${GUEST_URL}auth/register`,
  verifyUser: `${GUEST_URL}auth/verifyUser`,

  repost: `${POST_URL}post/nft/avatar/spread`,
  getUserSearch: `${USER_URL}user/search/list`,
  saveAvatar: `${USER_URL}user/avatar/save`,
  setVerifiedAvatarBadge: `${USER_URL}user/avatar/set?badge_type=avatar_verification`,
  updatePassword: `${USER_URL}user/setting/password/update`,
};
