import Web3 from 'web3';
import { AVATAR_ACCESS_TOKEN_KEY } from '../enums';

// won't work in IE
export const isObjEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

// https://stackoverflow.com/a/47767860
export const getMediaExtensionFromURL = (url) => {
  return url && url.split(/[#?]/)[0].split('.').pop().trim();
};

// TODO: Change key name to something unique across all project ( and 3rd party ? )
export const getTorumToken = () => {
  return localStorage.getItem(AVATAR_ACCESS_TOKEN_KEY);
};

export const getShortenedAddress = (addr) => {
  if (!addr) return '';
  return addr.substring(0, 7) + '...' + addr.substring(addr.length - 4, addr.length);
};

export const getShortenedText = (str, chars = 24) => {
  if (!str) return '';
  return str.substring(0, chars) + '...';
};

export const getWalletBalance = async (account) => {
  if (!account) return 'no account given';

  const web3 = new Web3(window.ethereum);
  const wei = await web3.eth.getBalance(account);
  const balance = web3.utils.fromWei(wei, 'ether');
  // TODO: Return only balance upto 4 decimal digits
  return balance;
};

export const sleepFor = (ms) => new Promise((res, rej) => setTimeout(res, ms));

export const getFileObjectFromBase64 = async (base64, customFileName = 'noname') => {
  try {
    const response = await fetch(base64);
    const blob = await response.blob();
    const file = new File([blob], customFileName, { type: 'image/png' });
    return file;
  } catch (e) {
    console.error(e);
  }
};

export const getBase64FromUrl = async (url) => {
  if (!url) return console.error('No URL passed');
  try {
    // timestamp added to make fetch url unique on every call
    // making url unique means it'll make a new GET call and it won't be read from cache if present
    const ts = new Date().getTime();
    const data = await fetch(url + `?t=${ts}`);
    // const data = await fetch(url)
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  } catch (e) {
    console.error(e);
  }
};
